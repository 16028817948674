<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Postavshiklar malumotlari</h3>
          </div>
        </div>

        <div class="card-body">
          <div>
            <v-tabs v-model="tab">
              <v-tab
                v-for="(item, index) in items"
                :key="item.tab"
                @click="tabNum(index)"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <div>
                  <div v-if="getCurrProviderDetails.juridical_type == 'JI'">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Jismoniy shaxs
                    </h4>
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.full_name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Pochta manzili</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.pochta_address"
                      />
                    </div>
                    <div class="form-group">
                      <label>Pasport raqami</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Pasport raqami"
                        :value="getCurrProviderDetails.passport_number"
                      />
                    </div>
                    <div class="form-group">
                      <label>Oblast</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.adress_oblast.name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Region</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.adress_region.name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.status.status"
                      />
                    </div>
                    <div class="form-group">
                      <label>Comments</label>
                      <textarea
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Comments"
                        :value="getCurrProviderDetails.comments"
                      ></textarea>
                    </div>
                  </div>

                  <div v-if="getCurrProviderDetails.juridical_type == 'YU'">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Yuridik shaxs
                    </h4>
                    <div class="form-group">
                      <label>Mijoz nomi</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="getCurrProviderDetails.full_name"
                      />
                    </div>
                    <div class="form-group">
                      <label>INN</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="getCurrProviderDetails.inn"
                      />
                    </div>
                    <div class="form-group">
                      <label>OKED</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.oked"
                      />
                    </div>

                    <div class="form-group">
                      <label>NDS raqami</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.code_nds"
                      />
                    </div>

                    <div class="form-group">
                      <label>Oblast</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.adress_oblast.name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Region</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.adress_region.name"
                      />
                    </div>
                    <div class="form-group">
                      <label>Status</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.status.status"
                      />
                    </div>
                    <div class="form-group">
                      <label>Mulchilik shakli</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.ownership.name"
                      />
                    </div>

                    <div class="form-group">
                      <label>Pochta manzili</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="getCurrProviderDetails.pochta_address"
                      />
                    </div>
                    <div class="form-group">
                      <label>Yuridik manzil</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="getCurrProviderDetails.yur_address"
                      />
                    </div>
                    <div class="form-group">
                      <label>Comments</label>
                      <textarea
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        disabled
                        :value="getCurrProviderDetails.comments"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Kontakt malumotlari
                  </h4>
                  <div class="mb-5 pb-5">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Director malumotlari
                    </h4>

                    <div
                      v-if="
                        getCurrProviderDetails.provider_director.length == 0
                      "
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          value=""
                          placeholder="Director malumotlari topilmadi"
                          class="
                            my-2
                            form-control form-control-solid form-control-lg
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div v-else>
                      <div class="form-group">
                        <div
                          v-for="phones in getCurrProviderDetails.provider_director"
                          :key="phones.id"
                        >
                          <label>FIO</label>
                          <input
                            type="text"
                            class="
                              mb-5
                              form-control form-control-solid form-control-lg
                            "
                            disabled
                            :value="phones.name"
                          />
                          <div v-if="phones.provider_director_phone == 0">
                            <label>Telefon raqami</label>
                            <input
                              type="text"
                              class="
                                mb-5
                                form-control form-control-solid form-control-lg
                              "
                              disabled
                              placeholder="Phone number is not found"
                            />
                          </div>
                          <div v-else>
                            <div
                              v-for="phone in phones.provider_director_phone"
                              :key="phone.id"
                            >
                              <label>Telefon raqami</label>
                              <input
                                type="text"
                                class="
                                  mb-5
                                  form-control
                                  form-control-solid
                                  form-control-lg
                                "
                                disabled
                                :value="phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-5 pb-5">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Hodim malumotlari
                    </h4>
                    <div
                      v-if="
                        getCurrProviderDetails.provider_contactpersonal
                          .length == 0
                      "
                    >
                      <div class="form-group">
                        <input
                          type="text"
                          value=""
                          placeholder="Hodim malumotlari topilmadi"
                          class="
                            my-2
                            form-control form-control-solid form-control-lg
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div v-else>
                      <div class="form-group">
                        <div
                          v-for="(
                            phones, index
                          ) in getCurrProviderDetails.provider_contactpersonal"
                          :key="phones.id"
                        >
                          <label class="font-weight-bold">
                            <span class="text-primary font-weight-bold mr-1"
                              >{{ index + 1 }}.</span
                            >
                            FIO
                          </label>
                          <input
                            type="text"
                            class="
                              mb-5
                              form-control form-control-solid form-control-lg
                            "
                            disabled
                            :value="phones.name"
                          />
                          <div
                            v-if="phones.provider_contactpersonal_phone == 0"
                          >
                            <label>Telefon raqam</label>
                            <input
                              type="text"
                              class="
                                mb-5
                                form-control form-control-solid form-control-lg
                              "
                              disabled
                              placeholder="Phone number is not found"
                            />
                          </div>
                          <div v-else>
                            <label>Telefon raqam</label>

                            <div
                              v-for="phonenum in phones.provider_contactpersonal_phone"
                              :key="phonenum.id"
                            >
                              <input
                                type="text"
                                class="
                                  mb-5
                                  form-control
                                  form-control-solid
                                  form-control-lg
                                "
                                disabled
                                :value="phonenum"
                              />
                            </div>

                            <label>Lavozim</label>
                            <input
                              type="text"
                              class="
                                mb-5
                                form-control form-control-solid form-control-lg
                              "
                              disabled
                              :value="phones.contact_position"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-5 pb-5">
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Bank malumotlari
                    </h4>

                    <div
                      v-if="
                        getCurrProviderDetails.provider_bank_account.length == 0
                      "
                    >
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Bank details not found"
                      />
                    </div>
                    <div v-else>
                      <div class="form-group">
                        <div
                          v-for="mfonum in getCurrProviderDetails.provider_bank_account"
                          :key="mfonum.id"
                        >
                          <label>MFO</label>
                          <input
                            type="text"
                            class="
                              mb-5
                              form-control form-control-solid form-control-lg
                            "
                            disabled
                            :value="mfonum.mfo"
                          />
                          <label>Bank hisob raqami</label>
                          <input
                            type="text"
                            class="
                              mb-5
                              form-control form-control-solid form-control-lg
                            "
                            disabled
                            :value="mfonum.account"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <!-- 3 -->
              <v-tab-item>
                <div>
                  <h4 class="mb-10 font-weight-bold text-dark">Shartnomalar</h4>
                  <div>
                    <v-data-table
                      :headers="providerContractHeaders"
                      :items="getProviderContract"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:item.contract_type="{ item }">
                        <div
                          class="status__name"
                          :class="item.contract_class_name"
                        >
                          {{ item.contract_type }}
                        </div>
                      </template>
                      <template v-slot:item.is_active="{ item }">
                        <div class="status__name" :class="item.is_active_color">
                          {{ item.is_active }}
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      tab: null,
      providerContractHeaders: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Postavshik nomi',
          value: 'provider_name'
        },
        {
          text: 'Inn',
          value: 'provider_inn'
        },
        {
          text: 'Raqam',
          value: 'contract_number'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Boshlanish',
          value: 'start_date'
        },
        {
          text: 'Tugash',
          value: 'end_date'
        },
        {
          text: 'Turi',
          value: 'contract_type'
        },
        {
          text: 'Status',
          value: 'is_active'
        }
      ],
      items: [
        {
          tab: 'Umumiy malumotlar'
        },
        {
          tab: 'Kontakt malumotlari'
        },
        { tab: 'Shartnomalar' }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('getProviderDetails', this.$route.params.id)
    this.$store.dispatch('getProviderContractById', this.$route.params.id)
  },
  computed: {
    getProviderContract() {
      return this.$store.getters.allProviderContractById
    },
    getCurrProviderDetails() {
      return this.$store.state.requests.providerDetails
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Postavshiklar' },
      { title: 'Postavshik malumotlari' }
    ])
  },
  methods: {
    tabNum(value) {
      console.log(value)
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
